<template>
  <div>
    <v-btn
      class="info font-weight-bold"
      v-show="btn_show"
      :disabled="btn_disable"
      @click="showDialog"
      ><span class="d-none d-sm-flex">エステ魂自動設定</span
      ><span class="d-sm-none">エス魂設定</span></v-btn
    >
    <!-- エステ魂自動更新時刻設定ダイアログ -->
    <v-dialog v-model="dialog" scrollable persistent width="400px">
      <v-card :loading="disabled" :disabled="disabled">
        <v-card-title>
          <span class="text-h5">エステ魂自動更新時刻設定</span>
        </v-card-title>
        <v-card-text>
          <h3 id="cast_sync_step1">時刻設定</h3>
          <div class="mt-2 mb-2">
            指定した時刻の0～10分後の間でスケジュールが毎日更新されます。停止する場合は、クリアボタンを押してから保存して下さい。
          </div>
          <v-form ref="refForm">
            <div class="d-flex flex-wrap mb-2">
              <div class="flex_center px-2 pt-5 pb-3">
                <div class="select_item">
                  <v-select
                    class="select_hour"
                    v-model="post_hour"
                    :items="selectITem.hourItem"
                    item-text="text"
                    item-value="value"
                    label="時"
                    dense
                    hide-details="false"
                    :rules="[timerCheck(post_hour, 'post_hour'), timerRolus]"
                  >
                  </v-select>
                </div>
                <div class="select_item ml-3">
                  <v-select
                    class="select_minute"
                    v-model="post_minute"
                    :items="selectITem.minuteItem"
                    item-text="text"
                    item-value="value"
                    label="分"
                    dense
                    hide-details="false"
                    :rules="[
                      timerCheck(post_minute, 'post_minute'),
                      timerRolus,
                    ]"
                  ></v-select>
                </div>
                <div class="select_item ml-3">
                  <general-button
                    btn_color="gray"
                    btn_block
                    @click-event="clearDialog"
                    ><template v-slot:icon
                      ><v-icon left>mdi-alarm</v-icon></template
                    >クリア</general-button
                  >
                </div>
              </div>
            </div>
          </v-form>
          <div class="mt-2 mb-2">
            ※店舗管理の「更新時間帯」に影響されず、ここで指定した時間でエステ魂に更新します。
          </div>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="6">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="6">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmit"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";
import setting from "@/common/setting.js";
import selectITem from "@/common/selectItem.js";
import _ from "lodash";

export default defineComponent({
  props: ["show_dialog"],
  setup(props, ctx) {
    const { router } = useRouter();
    const ShopPropsRepository = repositoryFactory.get("shopProps");
    const updateContentsRepository = repositoryFactory.get("updateContents");
    const refForm = ref();

    const state = reactive({
      dialog: false, // ダイアログ表示用
      disabled: false,
      btn_disable: false,
      btn_show: false,
      post_hour: null,
      post_minute: null,
      timerRolus: true,
      // time_zone: [],
    });

    const showDialog = () => {
      const shopSites = store.getters["shop_sites/getShopSiteList"];
      let timer = null;
      // let time_zone = setting.time_zone_default;
      for (let i = 0; i < shopSites.length; i++) {
        if (shopSites[i].sites_id == setting.esutama_site_id) {
          // エステ魂のスケジュール設定があるか確認する(店舗項目:"esutamaschedule")
          const shop_data = store.getters["shops/currentShop"].data;
          if (shop_data.prop) {
            for (let j = 0; j < shop_data.prop.length; j++) {
              if (shop_data.prop[j].key == "esutamaschedule") {
                timer = shop_data.prop[j].value;
              }
              // if (shop_data.prop[j].key == "timezone") {
              //   time_zone = shop_data.prop[j].value;
              // }
            }
          }
        }
      }
      if (timer) {
        const tmp = timer.split(":");
        state.post_hour = Number(tmp[0]);
        state.post_minute = Number(tmp[1]);
      }
      // state.time_zone = time_zone == "" ? [] : time_zone.split(",");

      state.dialog = true;
    };

    // 入力された値のチェック
    const timerCheck = (input_data, itemName) => {
      if (itemName == "post_hour") {
        if (state.post_minute != null && input_data != null) {
          state.timerRolus = true;
          return true;
        }
        if (state.post_minute == null && input_data == null) {
          state.timerRolus = true;
          return true;
        }
      }
      if (itemName == "post_minute") {
        if (state.post_hour != null && input_data != null) {
          state.timerRolus = true;
          return true;
        }
        if (state.post_hour == null && input_data == null) {
          state.timerRolus = true;
          return true;
        }
      }
      state.timerRolus = false;
      return false;
    };

    // 保存処理
    const onSubmit = async () => {
      if (!refForm.value.validate()) {
        alert("時刻指定に誤りがあります。");
        return false;
      }

      // エステ魂のスケジュール更新は、店舗管理の更新時間帯の影響を受けない
      // if (state.post_hour != null) {
      //   const h = parseInt(state.post_hour);
      //   if (!state.time_zone.some((hour) => parseInt(hour) === h)) {
      //     alert(
      //       "更新時間の範囲外の時刻を指定しています。\n※店舗管理の更新時間帯を確認して下さい。"
      //     );
      //     return false;
      //   }
      // }

      // ローディング表示
      store.dispatch("loadingIcon/showIcon");
      state.disabled = true;

      // 保存処理
      let timer = "";
      if (state.post_hour != null && state.post_minute != null) {
        timer =
          ("00" + state.post_hour).slice(-2) +
          ":" +
          ("00" + state.post_minute).slice(-2);
      }
      store.dispatch("shops/setProp", {
        "key": "esutamaschedule",
        "value": timer,
      });

      // DB更新処理
      const shopData = store.getters["shops/currentShop"].data;
      let prop = null;
      if (shopData.prop) {
        for (let j = 0; j < shopData.prop.length; j++) {
          if (shopData.prop[j].key == "esutamaschedule") {
            prop = shopData.prop[j];
            break;
          }
        }
      }

      // shop_propsの更新
      const params = {
        id: prop.id,
        shop_id: prop.shop_id,
        key: prop.key,
        value: prop.value,
      };
      if (prop.id != 0) {
        // DBの更新
        await ShopPropsRepository.update(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:shops.js/setProp ShopPropsRepository.update (" +
              error +
              ")"
            );
          });
      } else {
        // DBに追加
        await ShopPropsRepository.create(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              prop.id = response.data.id;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:shops.js/setProp ShopPropsRepository.create (" +
              error +
              ")"
            );
          });
      }

      // エステ魂 自動更新設定
      await updateContentsRepository
        .save_esutama_schedule({ shop_id: prop.shop_id })
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shops.js/setProp ShopPropsRepository.create (" + error + ")"
          );
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
      state.disabled = false;
      // ダイアログ閉じる
      state.dialog = false;
    };

    const init = () => {
      // エステ魂自動設定ボタンを表示するかどうか判定
      const shopSites = store.getters["shop_sites/getShopSiteList"];
      for (let i = 0; i < shopSites.length; i++) {
        if (shopSites[i].sites_id == setting.esutama_site_id) {
          state.btn_show = true;
          break;
        }
      }
    };

    init();

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      state.post_hour = null;
      state.post_minute = null;
      state.dialog = false;
    };

    // 時刻設定をクリア
    const clearDialog = () => {
      state.post_hour = null;
      state.post_minute = null;
    };

    return {
      setting,
      selectITem,
      ...toRefs(state),
      refForm,
      onSubmit,
      cancelDialog,
      showDialog,
      clearDialog,
      timerCheck,
    };
  },
});
</script>

<style scoped>
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select_item {
  width: 5em;
}
</style>
